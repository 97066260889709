
/**
 * @file
 * 
 * Script d'initialisation du namespace `bnpp` et des fonctions de base pour toutes les applications.
 * 
 * Le namespace `bnpp` est défini s'il n'existe pas déjà ; sinon (ex. CCI) on le réutilise.
 *
 *  - Ce script DOIT être chargé *avant* RequireJs
 *  - La variable `ENVIRONNEMENT` DOIT être définie
 *  
 * @version 1.1.11-prod201610-3:186872
 * @license Copyright © 2016 BNP Paribas. Tous droits réservés.
 */

var bnpp = "undefined" != typeof bnpp ? bnpp : {};

bnpp.version = {
    version: "1.1.11-prod201610-3",
    revision: "186872"
}, "undefined" == typeof bnpp.config && (bnpp.config = {}), "undefined" == typeof bnpp.config.errors && (bnpp.config.errors = {
    maxCount: 5,
    urlWhiteList: !1,
    threshold: 10,
    rethrow: !1,
    _count_B094FCAB: 0,
    _rid_54A8FB77: -1
}), "undefined" == typeof bnpp.sf31 && (bnpp.sf31 = {}), "undefined" == typeof console && (console = {});

for (var fields = [ "debug", "error", "exception", "info", "log", "trace", "warn" ], f = 0; f < fields.length; f++) "undefined" == typeof console[fields[f]] && (console[fields[f]] = function() {});

bnpp._isProd_2A947328 = function() {
    return "undefined" == typeof ENVIRONNEMENT || "INTE" != ENVIRONNEMENT && "QUALIF" != ENVIRONNEMENT;
}, bnpp.log = function() {
    if ("undefined" != typeof DEBUG && DEBUG || !bnpp._isProd_2A947328()) {
        var e = console.log;
        Function.prototype.bind && window.console && "object" == typeof console.log && (e = Function.prototype.call.bind(console.log, console)), 
        e.apply(console, arguments);
    }
}, "undefined" == typeof trace && (trace = bnpp.log), bnpp.config._savOptions_DB6B4D88 = function() {
    function e(e) {
        return document.cookie.length > 0 && (c_start = document.cookie.indexOf(e + "="), 
        -1 != c_start) ? (c_start = c_start + e.length + 1, c_end = document.cookie.indexOf(";", c_start), 
        -1 == c_end && (c_end = document.cookie.length), unescape(document.cookie.substring(c_start, c_end))) : "";
    }
    var n = {};
    try {
        var r = e("bnpp_sav");
        r && "" != r && (n = JSON.parse(r));
    } catch (p) {
        bnpp.log(p);
    }
    return "undefined" != typeof n.tracing || bnpp._isProd_2A947328() || (n.tracing = !0), 
    n;
}(), bnpp.config._savOptions_DB6B4D88.tracing && (DEBUG = !0, bnpp.config.errors.urlWhiteList = !1, 
bnpp.config.errors.threshold = 100, bnpp.config.errors.rethrow = !0, bnpp.config.errors.maxCount = 20), 
bnpp._isErrorReportingOn_FA36C50F = function() {
    if (bnpp.config.errors._rid_54A8FB77 < 0) {
        var e = Math.random();
        bnpp.config.errors._rid_54A8FB77 = Math.floor(99 * e) + 1;
    }
    if (bnpp.config.errors._rid_54A8FB77 > bnpp.config.errors.threshold) return !1;
    if (bnpp.config.errors._count_B094FCAB >= bnpp.config.errors.maxCount) {
        if (bnpp.config.errors._count_B094FCAB != bnpp.config.errors.maxCount) return !1;
        bnpp.log("[WARN]  Nombre maximum d'erreurs atteint (" + bnpp.config.errors.maxCount + ") : les prochaines seront ignorées");
    }
    return bnpp.config.errors.urlWhiteList && !location.href.match(bnpp.config.errors.urlWhiteList) ? !1 : !0;
}, bnpp.onError = function(e, n, r, p, o) {
    if (bnpp.config.errors._count_B094FCAB++, !bnpp._isErrorReportingOn_FA36C50F()) return !0;
    var i = r, t = "undefined" != typeof p ? p : -1, s = n, u = "", a = e, c = e, f = window.location.href.replace(/(\?.*)$/, "?...");
    "undefined" != typeof a && null != a && ("undefined" != typeof a.message && null != a.message && (a = a.message), 
    "function" == typeof a.toString && (a = a.toString()), "function" == typeof a.substring && (a = a.substring(0, 128))), 
    "undefined" != typeof o && null != o && (c = o), "undefined" != typeof c && null != c && ("undefined" != typeof c.lineNumber && null != c.lineNumber && (i = "function" == typeof c.lineNumber.toString ? c.lineNumber.toString() : c.lineNumber), 
    "undefined" != typeof c.fileName && null != c.fileName && (s = "function" == typeof c.fileName.toString ? c.fileName.toString() : c.fileName), 
    "undefined" != typeof c.requireType && (u = "require-" + c.requireType));
    var l = {
        url: f,
        loc: s,
        ln: i,
        col: t,
        err: a,
        typ: u
    }, b = Number(new Date()), d = "/rsc/sys/image/error_js.gif?timestamp=" + b, g = new XMLHttpRequest();
    if (g.open("HEAD", d, !0), g.setRequestHeader("Error-js", JSON.stringify(l)), g.send(), 
    bnpp.config.errors.rethrow) throw c;
    return bnpp.log("[ERROR] " + a, " " + s, ":" + i, l), !0;
}, onerror = bnpp.onError, bnpp.config.require = {
    baseUrl: "/rsc/contrib/script",
    shim: {
        backbone: {
            deps: [ "underscore", "jquery" ],
            exports: "Backbone"
        },
        carrousel: {
            deps: [ "jquery" ]
        },
        chart: {
            exports: "Chart"
        },
        donuts: {
            exports: "Chart"
        },
        checkstatus: {
            deps: [ "jquery-legacy" ]
        },
        handlebars: {
            exports: "Handlebars"
        },
        "handlebars.helpers": {
            deps: [ "jquery", "handlebars" ]
        },
        helpers: {
            deps: [ "jquery-legacy" ]
        },
        jqueryui: {
            deps: [ "jquery" ],
            exports: "jqueryui"
        },
        "jquery.cookie": {
            deps: [ "jquery" ]
        },
        "jquery.validator": {
            deps: [ "jquery" ]
        },
        nanoscroller: {
            deps: [ "jquery" ],
            exports: "NanoScroller"
        },
        rivetConfig: {
            exports: "rivetConfig"
        },
        underscore: {
            deps: [ "jquery" ],
            exports: "_"
        },
        wrappernext: {
            deps: [ "backbone", "moment" ],
            exports: "wrappernext"
        }
    },
    config: {
        moment: {
            noGlobal: !0
        },
        "moment-2.6": {
            noGlobal: !0
        }
    },
    paths: {
        backbone: "generique/bnpp/backbone-1.0.0",
        Blob: "generique/bnpp/Blob-079824b",
        bnppFormat: "generique/bnpp/bnpp-format-1",
        "bnpp-legacy": "generique/bnpp/bnpp-legacy-1",
        carrousel: "generique/bnpp/carrousel-1f6f33d8",
        chart: "generique/bnpp/chart-1.0.1-bnpp",
        donuts: "generique/bnpp/doughnut-af913fb7",
        flipclock: "generique/bnpp/flipclock-8a79e5d6",
        handlebars: "generique/bnpp/handlebars-v1.3.0",
        "handlebars.helpers": "generique/bnpp/handlebar-helpers-9c725d09",
        helpers: "generique/bnpp/helpers-4aa9d0ae",
        ics: "generique/bnpp/ics-0.1.3",
        "iFrameResize.contentWindow": "generique/bnpp/iframeResizer.contentWindow-v2.6.1-0-g729fb0a",
        iFrameResize: "generique/bnpp/iframeResizer-v2.6.1-0-g729fb0a",
        jquery: "generique/bnpp/jquery/jquery-1.11.0",
        "jquery-bnpp": "generique/bnpp/jquery/jquery-bnpp-1.3",
        jqueryui: "generique/bnpp/jquery/jquery-ui-1.11.4.custom",
        "jquery.cookie": "generique/bnpp/jquery/jquery.cookie-1.4.1-cf67d43f",
        "jquery.validator": "generique/bnpp/jquery/jquery.validate-1.11.1",
        matchMedia: "generique/bnpp/matchMedia-ad2dee6e",
        moment: "generique/bnpp/moment-2.9.0-fr",
        "moment-2.6": "generique/bnpp/moment-2.6.0-bnpp",
        nanoscroller: "generique/bnpp/jquery/jquery.nanoscroller-0.8.0",
        pagebus: "generique/bnpp/pagebus-2.0.0",
        Porthole: "generique/bnpp/porthole-d3dcc86",
        "require-plugins": "generique/bnpp/require/require-plugins-1.0",
        rivets: "generique/bnpp/rivets-0.6.5-bnpp1",
        saveAs: "generique/bnpp/FileSaver-9751591",
        sf: "generique/bnpp/sf-102aac3d",
        underscore: "generique/bnpp/underscore-1.4.4",
        "waypoints-bundle": "generique/bnpp/jquery/waypoints-bundle-2.0.5",
        wcm: "generique/bnpp/sf/wcm-71ad06a7",
        wrappernext: "generique/bnpp/backbone.wrappernext-1.1.3"
    },
    map: {
        "*": {
            jquery: "jquery-bnpp",
            "sf/axes": "axes",
            "sf/wcm": "wcm",
            waypoint: "waypoints",
            sticky: "waypoints-stickyelements"
        },
        "jquery-bnpp": {
            jquery: "jquery"
        },
        wrappernext: {
            moment: "moment-2.6"
        }
    },
    bundles: {
        "bnpp-legacy": [ "canvasBuilder", "ChatSystem", "checkstatus", "clickablePDF", "clickableRow", "Conseiller", "CustomDropdown", "DateHelper", "Datepicker", "DeviceSize", "FormHelper", "HelpText", "jquery-legacy", "NavigationGlobal", "Popin", "preventPasteOnConfirm", "ShowHideHelper" ],
        "require-plugins": [ "normalize", "css", "text" ],
        rivets: [ "rivets-src" ],
        sf: [ "sf/appcontext", "sf/csrf", "sf/errlog", "sf/fluid", "sf/form", "sf/history", "sf/messaging", "wcm", "axes" ],
        "waypoints-bundle": [ "waypoints", "waypoints-stickyelements" ],
        "require-plugins": [ "css", "normalize", "text" ],
        sf: [ "axes", "sf/appcontext", "sf/csrf", "sf/errlog", "sf/fluid", "sf/form", "sf/history", "sf/messaging" ],
        "waypoints-bundle": [ "waypoints", "waypoints-stickyelements" ]
    },
    deps: [ "backbone", "chart", "donuts", "jquery", "jqueryui", "handlebars", "handlebars.helpers", "rivets", "underscore", "waypoints", "wrappernext" ],
    onError: bnpp.onError,
    callback: function() {
        bnpp.log("bnpp.* initialisé :", bnpp);
    },
    waitSeconds: 60
}, "undefined" == typeof require && (require = {}), require.waitSeconds = bnpp.config.require.waitSeconds, 
require.onError = bnpp.onError, bnpp.jquery = function() {
    var e = arguments;
    bnpp.sf31.require([ "jquery" ], function(n) {
        return n.apply(this, e);
    });
}, bnpp.checkStatus = function() {
    var e = arguments;
    bnpp.sf31.require([ "checkstatus" ], function(n) {
        return bnpp.log.apply(n, e), n.apply(window, arguments);
    });
}, "undefined" == typeof tagguageNext && (tagguageNext = function() {
    return trace("fake taguage next"), "fake";
});